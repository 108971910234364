import React from 'react'

function CopyRight() {
  return (
    <div>
        <div className="footer-Copyright text-center py-6">
          &copy; {new Date().getFullYear()} copyright: sagalgeneraltrade.com
      </div>
    </div>
  )
}

export default CopyRight